<template>
    <div class="main-container ">
      <el-breadcrumb separator="/" class="breadcrumb" style="margin:0.6rem;display: inline-block;">
        <el-breadcrumb-item style="">资产盘点</el-breadcrumb-item>
        <el-breadcrumb-item>盘点详情</el-breadcrumb-item>
      </el-breadcrumb>
      <el-link size="mini" type="primary"  icon="el-icon-back" class="back-btn" @click="goBack">返回上一页</el-link>
      <div class="content-block">
      <basic-info ref="basicInfo" ></basic-info>
      <el-divider></el-divider>
      <ac-dtl-table v-if="page_type == 'dtl'" @updateDetail="updateDetail"></ac-dtl-table>
      <ac-report-table v-if="page_type == 'report'"></ac-report-table>
      </div>
    </div>
</template>
<script>
import BasicInfo from "@/views/AssetCheck/components/basicInfo.vue";
import AcDtlTable from "@/views/AssetCheck/components/acDtlTable.vue";
import AcReportTable from "@/views/AssetCheck/components/acReportTable.vue";
import {getInventoryDtl} from "@/api/assetCheck";


export default {
  name: "acDtl",
  components: {AcReportTable, AcDtlTable, BasicInfo},
  data(){
    return{
      page_type: this.$route.query.page_type,
    }
  },
  created() {
    // this.getDtl()
  },
  methods:{
    updateDetail(val){
      if(val){
        this.$refs.basicInfo.getDtl()
      }
    },
    goBack(){
      this.$router.push({ path: '/assetCheck/list/' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
.zone1{
  padding: px(8)  px(24);
}
</style>