var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sheet"},[_c('div',{staticClass:"item-title"},[_c('img',{staticClass:"descriptions-icon",attrs:{"src":require("@/assets/img/titleIcon.png")}}),_c('span',{staticClass:"descriptions-title",staticStyle:{"margin-left":"8px","margin-bottom":"14px"}},[_vm._v("资产列表")]),_c('span',{staticClass:"descriptions-title",staticStyle:{"margin-left":"30px","margin-bottom":"14px"}},[_c('el-radio-group',{on:{"change":function($event){return _vm.handleStatusChange()}},model:{value:(_vm.invent_status),callback:function ($$v) {_vm.invent_status=$$v},expression:"invent_status"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("全部")]),_c('el-radio',{attrs:{"label":1511}},[_vm._v("已盘")]),_c('el-radio',{attrs:{"label":1510}},[_vm._v("未盘")])],1)],1),_c('span',{staticClass:"descriptions-title",staticStyle:{"margin-left":"30px","margin-bottom":"14px"}},[_c('el-input',{staticStyle:{"display":"inline","font-size":"14px"},attrs:{"clearable":"","label":"","size":"mini","placeholder":"医院/经销商/机号"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('el-button',{staticStyle:{"margin-left":"10px","padding":"6px 30px","font-size":"14px"},attrs:{"type":"primary","size":"mini"},on:{"click":_vm.getTableList}},[_vm._v("搜 索")])],1),_c('span',[_c('el-button',{staticClass:"confirm-btn",attrs:{"type":"primary"},on:{"click":_vm.goConfirm}},[_vm._v("开始盘点")])],1)]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData,"stripe":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","disabled":"","width":"55","fixed":"left"}}),_c('el-table-column',{attrs:{"prop":"is_inventory","label":"盘点状态","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:({color:row.is_inventory == '未盘' ? '#BD3124' : '#81B337'})},[_vm._v(_vm._s(row.is_inventory ? row.is_inventory : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"instrument_id","label":"仪器序列号","width":"130","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.instrument_id ? row.instrument_id : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"platform_unique","label":"仪器型号","min-width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.platform_unique ? row.platform_unique : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"asset_status","label":"资产状态","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.asset_status_name ? row.asset_status_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"hospital_name","label":"医院","min-width":"270","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.hospital_name ? row.hospital_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"hospital_leve","label":"医院等级","min-width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.hospital_level ? _vm.hospitalLevelOptions.filter(function (item) { return item.val == row.hospital_level; })[0].name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"label","label":"仪器位置","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.label_id ? _vm.locationTagOptions.filter(function (item) { return item.val == row.label_id; })[0].name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"customize_label","label":"仪器位置标签","width":"130","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.customize_label ? row.customize_label : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"position_now","label":"现存位置","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.position_now ? _vm.handlePosition(row.position_now).positionName : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"primary_distributor","label":"一级经销商","min-width":"230","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.primary_distributor ? row.primary_distributor : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"terminal_distributor","label":"终端经销商","width":"380","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.terminal_distributor ? row.terminal_distributor : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"check_name","label":"盘点人员","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.check_name ? row.check_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"check_time","label":"盘点时间","width":"250","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.check_time ? row.check_time : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"盘点备注","width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.remarks ? row.remarks : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"is_change","label":"是否有更新","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.is_change == 1 ? '是' : '否'))])]}}])})],1),_c('div',{staticClass:"el_pagination_style"},[_c('el-pagination',{attrs:{"hide-on-single-page":_vm.total <= 0,"background":"","layout":"total,sizes,prev, pager, next,jumper","total":_vm.total,"page-sizes":[10, 20, 30, 50],"page-size":_vm.size,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}})],1),(_vm.dialogVisible)?_c('el-dialog',{attrs:{"title":"盘点确认","visible":true,"width":"90%","min-height":"500px","top":"50px"},on:{"close":_vm.closeConfirm}},[_c('ac-confirm',{ref:"acConfirm",attrs:{"rowData":_vm.selectedRows},on:{"getIsSubmit":_vm.getIsSubmit}}),_c('span',{staticClass:"dialog-footer",staticStyle:{"text-align":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"disabled":!_vm.isSubmit,"type":"primary","size":"small"},on:{"click":function($event){return _vm.confirmSubmit()}}},[_vm._v("确认提交")]),_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.closeConfirm}},[_vm._v("关闭")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }