<template>
  <div class="sheet">
    <div class="item-title">
      <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
      <span class="descriptions-title" style="margin-left: 8px;margin-bottom: 14px;font-size: 16px">资产列表</span>
      <span class="descriptions-title" style="margin-left: 30px;margin-bottom: 14px">
        <el-radio-group v-model="invent_status" @change="handleStatusChange()">
          <el-radio :label="0">全部</el-radio>
          <el-radio :label="1511">已盘</el-radio>
          <el-radio :label="1510">未盘</el-radio>
        </el-radio-group>

      </span>
      <span  class="descriptions-title" style="margin-left: 30px;margin-bottom: 14px;" >
        <el-input v-model="search" style="display: inline"  label="" size="mini" clearable placeholder="医院/经销商/机号"></el-input>
        <el-button type="primary" style="margin-left: 10px;padding: 6px 30px;font-size: 14px" @click="getTableList" size="mini">搜 索</el-button>
      </span>
      <el-button v-if="assetMGtObj.checkExport || [110, 103, 109].includes(userRoleId)" style="background: #81b337;color:#fff;float:right" @click="handleExport">下载报告</el-button>
    </div>
    <el-table :data="tableData" stripe v-loading="loading" :element-loading-text="loadingText">
      <el-table-column prop="is_inventory" label="盘点状态" width="90" align="center">
        <template v-slot="{row}">
          <span :style="{color:row.is_inventory == '未盘' ? '#BD3124' : '#81B337'}">{{row.is_inventory ? row.is_inventory : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="asset_no" label="固定资产编号" width="150" align="center">
        <template v-slot="{row}">
          <span>{{row.asset_no ? row.asset_no : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="instrument_id" label="仪器序列号" width="110" align="center">
        <template v-slot="{row}">
          <span>{{row.instrument_id ? row.instrument_id : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="platform_unique" label="仪器型号"  min-width="170" align="center">
        <template v-slot="{row}">
          <span>{{row.platform_unique ? row.platform_unique : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="asset_status_name" label="资产状态"  width="100" align="center">
        <template v-slot="{row}">
          <span>{{row.asset_status_name ? row.asset_status_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="area_info" label="所在区域"  min-width="100" align="center">
        <template v-slot="{row}">
          <span>{{row.area_info ? row.area_info : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hospital_name" label="医院名称"  min-width="290" align="center">
        <template v-slot="{row}">
          <span>{{row.hospital_name ? row.hospital_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="医院等级"  min-width="100" align="center">
        <template v-slot="{row}">
          <span>{{row.hospital_level ? hospitalLevelOptions.filter(item => item.val == row.hospital_level)[0].name  : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hospital_adress" label="医院地址"  min-width="300" align="center">
        <template v-slot="{row}">
          <span>{{row.hospital_address ? row.hospital_address : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="label" label="仪器位置"  width="130" align="center">
        <template v-slot="{row}">
          <span>{{row.label_id ? locationTagOptions.filter(item => item.val == row.label_id)[0].name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customize_label" label="仪器位置标签"  width="160" align="center">
        <template v-slot="{row}">
          <span>{{row.customize_label ? row.customize_label : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="position_now_name" label="现存位置"  width="90" align="center">
        <template v-slot="{row}">
          <span>{{row.position_now_name ? row.position_now_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="location_info" label="定位信息"  width="120" align="center">
        <template v-slot="{row}">
          <div v-if="row.lac_lng">
            <p style="font-size:10px;">LAC:{{row.lac_lng ? (row.lac_lng.lac? row.lac_lng.lac : '/' ) :'/' }}</p>
            <span style="font-size:10px;">CELLID:{{row.lac_lng ? (row.lac_lng.callID ? row.lac_lng.callID : '/') : '/'}}</span>
          </div>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="primary_distributor" label="一级经销商"  width="290" align="center">
        <template v-slot="{row}">
          <span>{{row.primary_distributor ? row.primary_distributor : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="terminal_distributor" label="终端经销商"  width="240" align="center">
        <template v-slot="{row}">
          <p v-if="row.terminal_distributor" >{{row.terminal_distributor}}</p>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="reagent_total" label="试剂总用量"  width="110" align="center">
        <template v-slot="{row}">
          <span>{{row.reagent_total ? row.reagent_total : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="repair_total" label="维修总计"  width="90" align="center">
        <template v-slot="{row}">
          <span>{{row.repair_total ? row.repair_total : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="upper_version" label="上位机版本号"  width="200" align="center">
        <template v-slot="{row}">
          <span>{{row.upper_version ? row.upper_version : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="latest_update_time" label="最后更新时间"  width="200" align="center">
        <template v-slot="{row}">
          <span>{{row.latest_update_time ? row.latest_update_time : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="engineer_name" label="售后工程师"  width="110" align="center">
        <template v-slot="{row}">
          <span>{{row.engineer_name ? row.engineer_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saler_name" label="销售人员"  width="90" align="center">
        <template v-slot="{row}">
          <span>{{row.saler_name ? row.saler_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="check_name" label="盘点人员"  width="90" align="center">
        <template v-slot="{row}">
          <p v-if="row.check_name" >{{row.check_name}}</p>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="check_time" label="盘点时间"  width="130" align="center">
        <template v-slot="{row}">
          <p v-if="row.check_time" >{{row.check_time}}</p>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column prop="check_name" label="是否有更新" width="100" align="center">
        <template v-slot="{row}">
          <span>{{row.is_change == 1 ? '是' : '否'}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="el_pagination_style">
      <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total,sizes,prev, pager, next,jumper"
          :total="total"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="size"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import checkCommon from "@/views/AssetCheck/mixin/checkCommon";
import {exportCheck} from "@/api/assetCheck";
import {addMenu, getAssetMgtBtn} from "@/router/menuMain";
import options from "@/views/AssetManagement/mixin/options";
export default {
  name: "acReportTable",
  mixins: [checkCommon,options],
  data(){
    return{
      userRoleId: JSON.parse(localStorage.getItem('userInfo')).org_post,
      loading: false,
      tableData:[],
      size: 10,
      page:1,
      total:0,
      search: '',
      invent_status:0,
      loadingText: '正在加载数据，请稍后',
      assetMGtObj:{
        checkExport: false, //盘点导出
      },
    }
  },
  created(){
    this.getTableList()
    let menuList = JSON.parse(localStorage.getItem('menuList'))
    addMenu(menuList);
    this.assetMGtObj = getAssetMgtBtn()// 缓存里取按钮权限
  },
  methods:{
    handleStatus(val){
      let statusName = ''
      switch (val){
        case '1001':
          statusName = '已盘'
          break;
        case '1002':
          statusName = '未盘'
          break;
      }
      return statusName
    },
    handleExport(){
      this.loading = true
      this.loadingText = '正在下载文件,请稍后'
      let parmas = {
        notes:this.$route.query.note_id
      }
      exportCheck(parmas).then(res =>{
        this.fileList = res.data
        let downLoadList = this.fileList.map(item => item.file_url)
        this.downloadFile(downLoadList[0])
      }).finally(()=>{
        this.loading = false
      })
    },
    downloadFile(url) {
      return new Promise((resolve, reject) => {
        const fileName = url.split('/').pop()
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.download = fileName
        // 这个延迟操作的目的是为了确保浏览器有足够的时间来处理下载操作
        // 这样可以避免在循环中快速触发多个下载操作，导致浏览器无法正确处理下载请求
        link.addEventListener('click', () => {
          setTimeout(() => {
            resolve()
          }, 1000)
        })
        link.click()
      }).finally(()=>{this.loading = false})
    },
    handleSizeChange(val){
      this.size = val
      // sessionStorage.setItem('pageParams', JSON.stringify({ pageParams:this.pageParams }));
      this.getTableList();
    },
    handleCurrentChange(val) { // 翻页
      this.page = val
      this.getTableList()
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
::v-deep .el-dialog__footer{
  text-align: center;
}
::v-deep .el-pagination__sizes{
  width: 80px;
  vertical-align: top;
}
::v-deep .el-pagination__sizes .el-select{
  vertical-align: top;
}
::v-deep .el-pagination__sizes input{
  width: 84px;
  height: 1.8rem;
  border: 1px solid #999999;
}
::v-deep .el-pagination .el-input__suffix{
  right: 22px;
}
::v-deep .el-range-editor--small .el-range-input{
  font-size: 0.75rem!important;
}
::v-deep .el-input__suffix .el-input__suffix-inner .el-input__icon {
  line-height: 16px;
}

::v-deep .el-input .el-input__inner{
  font-size: 14px;
}
</style>